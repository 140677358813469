import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import "./playlist.css";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  Checkbox,
  Typography,
  FormControl,
  InputLabel,
  Input,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import PlaylistCard from "./component/playlist-card/PlaylistCard";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  addPlaylist,
  deletePlaylist,
  deleteContentFromPlaylist,
  updateDuration,
  getPlaylist,
  playlistFlagOff,
  updatePlaylist,
  deletePlaylistSchedule,
  getPlaylistsOnFolder,
  getPlaylistByPredecessor,
} from "../../Redux/Actions/playlistAction";
// import { DeleteOutline } from "@mui/icons-material";
// import { imgUrl } from "../../api/api";
import Tab from "../tab/Tab";
import PrimarySearchBar from "../../components/searchBar/searchbar";
// import DeleteIcon from '../../Assets/images/delete.svg';
// import { ReactComponent as DeleteIcon } from "../../Assets/images/delete.svg";
import { getScreens } from "../../Redux/Actions/screenAction";
import toast from "react-hot-toast";
import PlaylistContentCardItem from "./component/content-card/PlaylistContentCardItem";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import dayjs from "dayjs";
import { getContent } from "../../Redux/Actions/contentAction";
import { PlaylistAddOutlined } from "@mui/icons-material";
import Loader from "../../components/loader/Loader";
import { ADD_CONTENT_IN_PLAYLIST, DELETE_CONTENT_FROM_PLAYLIST, DELETE_PLAYLIST, PLAYLIST_CONTNET_SORTING, PLAYLIST_PERMISSION_SUCCESS, PLAYLIST_SCHEDULE_DELETE, PLAYLIST_SCHEDULE_SUCCESS, PLAYLIST_TO_MULTIPLE_SCREEN_SUCCESS, UPDATE_DURATION, UPDATE_IMAGE_RESOLUTION } from "../../Redux/constant";
import _default from "react-redux/es/components/connect";
import useFilter from "../../hooks/useFilter";
import { useParams } from "react-router-dom";
import FolderModel from "../../components/models/FolderModel";
import FolderWrapper from "../../components/folder/FolderWrapper";
import FolderComponent from "../../components/folder/FolderComponent";
// import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
function Playlist({ auth, user, socketRef }) {
  const { _id } = useParams();
  const id = _id == null ? "Home" : _id;
  const dispatch = useDispatch();
  const {
    playlist,
    content,
    isPlaylistAdded,
    isPlaylistDeleted,
    isPlaylistUpdated,
    isPlaylistLoading,
    isPlaylistPermissionUpdated,
    isPlaylistScheduled,
    isPlaylistScheduledDeleted,
    isSorted,
    type,
    _playlistId,
    isPlaylistAssign
  } = useSelector((state) => state.Playlist);
  const { _folders, folders } = useSelector(
    (state) => ({
      folders: state.Folder.folders.filter(
        (f) => f.module == "playlist" && f.parent == id
      ),
      _folders: state.Folder.folders.filter((f) => f.module == "playlist"),
      // screens: state.Screen.screens,
    }),
    shallowEqual
  );
  const [isFolderOpen,setIsFolderOpen]=useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [_folderId, setFolderIds] = useState("");
  const [value, setValue] = useState(dayjs("2014-08-18T21:11:54"));
  const { screens } = useSelector((state) => state.Screen);
  const [openModel, setOpenModel] = useState(false);
  const [openContentModel, setOpenContentModel] = useState(false);
  const [name, setName] = useState("");
  const [pId, setPID] = useState("");
  const [screenMac, setScreenMac] = useState("");
  const [playlistId, setPlaylistId] = useState("");
  const [macList,setMacList]=useState([]);
  const [contentId, setContentId] = useState("");
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);
  const [openTimeDialoge, setOpenTimeDialoge] = useState(false);
  const [openSchedule, setOpenSchedule] = useState(false);
  const [textVal, setTextVal] = useState("");
  const [filteredPlaylist, setFilteredPlaylist] = useState([]);
  const [contentID, setContentID] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [day, setDay] = useState([]);
  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduleInfo, setScheduleInfo] = useState({});
  const filtered=useFilter(textVal,playlist)();
  const [days, setDays] = useState([
    { _id: 1, day: "Mon", dayChecked: false },
    { _id: 2, day: "Tue", dayChecked: false },
    { _id: 3, day: "Wed", dayChecked: false },
    { _id: 4, day: "Thu", dayChecked: false },
    { _id: 5, day: "Fri", dayChecked: false },
    { _id: 6, day: "Sat", dayChecked: false },
    { _id: 7, day: "Sun", dayChecked: false },
  ]);
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (id !== "Home") {
      dispatch(getPlaylistsOnFolder(id));
    }
  }, [id]);
  useEffect(() => {
   // socketRef.current.emit("leaving--connection");
    if (auth) {
      try {
        if(user?.role=='user-admin'){
          dispatch(getPlaylist(user?.parentId?._id));
        dispatch(getScreens(user?.parentId?._id));
        dispatch(getContent(user?.parentId?._id));
        }else{
          if(user.role=='company'){
            dispatch(getPlaylist(user?._id));
          }else{
            dispatch(getPlaylistByPredecessor(user?._id));
          }
          dispatch(getScreens(user?._id));
          dispatch(getContent(user?._id));
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  }, [auth]);
  useEffect(() => {
    if (isPlaylistUpdated) {
      // toast.success("Updated successfully!");
      // console.log(playlist,_playlistId)
      setIsScheduled(false);
      setFilteredPlaylist([...playlist]);
      let _playlist=playlist.find(p=>p._id===_playlistId);
      // console.log(playlist)
       switch(type){
        case DELETE_CONTENT_FROM_PLAYLIST:
        MessageShow("Content deleted from playist."); 
        _playlist?.mac_list.forEach(m=>{
            sendEmitOnServer("event:delegate",m)
          })
          break;
          case ADD_CONTENT_IN_PLAYLIST:
            MessageShow("Content added in playist."); 
            _playlist?.mac_list.forEach(m=>{
              console.log('from inside',m)
              sendEmitOnServer("event:delegate",m)
            })
            break;
            case UPDATE_DURATION:
              MessageShow("Duration updated."); 
              _playlist?.mac_list.forEach(m=>{
                sendEmitOnServer("event:delegate",m)
              })  
            break;
            case UPDATE_IMAGE_RESOLUTION:
              MessageShow("Image resolution updated."); 
              _playlist?.mac_list.forEach(m=>{
                sendEmitOnServer("event:delegate",m)
              })  
            break;
            case PLAYLIST_SCHEDULE_SUCCESS:
              MessageShow("Schedule assigned successfully."); 
              setIsScheduled(false);
              _playlist?.mac_list.forEach(m=>{
                sendEmitOnServer("event:delegate",m);
              })  
              break;
              case PLAYLIST_SCHEDULE_DELETE:
                MessageShow("Schedule deleted successfully."); 
                setIsScheduled(false);
                _playlist?.mac_list.forEach(m=>{
                  sendEmitOnServer("event:delegate",m);
                })  
                break;
                case PLAYLIST_CONTNET_SORTING:
                  MessageShow("Content sorted."); 
                  // setIsScheduled(false);
                  _playlist?.mac_list.forEach(m=>{
                    sendEmitOnServer("event:delegate",m);
                  })  
                  break;
                  case DELETE_PLAYLIST:
                    MessageShow("Playlist deleted successfully."); 
                  // setIsScheduled(false);
                  macList?.forEach(m=>{
                    sendEmitOnServer("event:delegate",m);
                  })  
                  break;
                  case PLAYLIST_TO_MULTIPLE_SCREEN_SUCCESS:
                    let _plyalistIndex=playlist.findIndex(p=>p._id===_playlistId)
                    MessageShow("Playlist assigned to multiple screen."); 
                  // setIsScheduled(false);
                  playlist[_plyalistIndex]?.mac_list.forEach(m=>{
                    console.log(m)
                    sendEmitOnServer("event:delegate",m);
                  })  
                  dispatch(playlistFlagOff());
                  break;
                  case PLAYLIST_PERMISSION_SUCCESS:
                    toast.success('Playlist permission has set.')
                    break;
          default:
            console.log('not from here')
 
       }
      dispatch(playlistFlagOff());
    }
    
  }, [
    isPlaylistUpdated,
    type,
    _playlistId
  ]);
  const sendEmitOnServer=(event,mac)=>{
    socketRef.current.emit(event,{detail:{mac}})
  }
  const MessageShow=(text)=>{
    toast.success(text);
  }
  const submit = () => {
    if(user.role=='user-admin'){
      dispatch(
        addPlaylist({
          name,
          user: user?.parentId?._id,
          folderId:id
        })
      );  
    }else{
      dispatch(
        addPlaylist({
          name,
          user: user?._id,
          folderId:id
        })
      );
    }
    setOpenModel(false);
  };
  const addContentModel = (id, mac) => {
    setPID(id);
    setPlaylistId(id);
    // here i checking screen assign to playlist or not
    const checkingScreenAssingnation = screens.find(
      (s) => s.playlist?._id === id
    );
    // console.log("from add content model", checkingScreenAssingnation);
    if (checkingScreenAssingnation !== undefined) {
      // console.log(checkingScreenAssingnation);
      setScreenMac(checkingScreenAssingnation.mac);
      setOpenContentModel(true);
    } else {
      setOpenContentModel(true);
    }
  };
  const deleteWholePlaylist = (id, mac) => {
    setPlaylistId(id);
    const _playlist=_playlist.find(p=>p._id==id);
    setMacList([..._playlist.mac_list]);
    const checkingScreenAssingnation = screens.find(
      (s) => s.playlist?._id === id
    );
    if (checkingScreenAssingnation !== undefined) {
      setScreenMac(checkingScreenAssingnation.mac);
      setOpenDeleteModel(true);
    } else {
      setOpenDeleteModel(true);
    }
  };
  const confirmDeletePlaylist = () => {
    dispatch(deletePlaylist(playlistId));
    setOpenDeleteModel(false);
  };
  const deleteContent = (playlistid, contentid, screenid) => {
    setPlaylistId(playlistid)
    dispatch(
      deleteContentFromPlaylist({
        playlistId: playlistid,
        contentId: contentid,
      })
    );
    
  };
  const updateTimeHandler = (playlistid, contentid, duration, mac) => {
    setOpenTimeDialoge(true);
    setPlaylistId(playlistid);
    setContentId(contentid);
    setSec(Math.floor((duration / 1000) % 60));
  };
 
  const updateTimeConfirm = () => {
    const obj = {
      playlistId,
      contentId,
      time: `${min}:${sec}`,
    };
    setOpenTimeDialoge(false);
    dispatch(updateDuration(obj));
    setMin(0);
    setSec(0);
   
  };
  const searchHandler = (e) => {
    setTextVal(e);
  };
  const addSchedule = (playlistid, contentid) => {
    // console.log(playlistid,contentid)
    const info = playlist.find((p) => p._id === playlistid);
    const content = info?.content?.find((c) => c._id === contentid);
    // console.log(info, content);
    const __startDate = new Date(content?.schedule?.startDate)
      .toLocaleDateString()
      .split("/");
    const __endDate = new Date(content?.schedule?.endDate)
      .toLocaleDateString()
      .split("/");

      let _startDate=''
     let _endDate=''
     const obj={
      start:{
        year:'',
        month:'',
        day:''
      },
      end:{
        year:'',
        month:'',
        day:''
      }
     }
      if(Number(__startDate[0]) < 10){
        console.log('start date first')
        obj.start.month=`0${__startDate[0]}`
     
     }
     else{
      obj.start.month=__startDate[0]
     }
      if( Number(__startDate[1]) < 10){
        // console.log('start date second')
        obj.start.day=`0${__startDate[1]}`
     
     }else{
      obj.start.day=__startDate[1]
     }
  

     if(Number(__endDate[0]) < 10) {
      obj.end.month=`0${__endDate[0]}`
     
     }else{
      obj.end.month=__endDate[0]
     }
      if(Number(__endDate[1]) < 10){
     obj.end.day=`0${__endDate[1]}`
      
     }else{
      obj.end.day=__endDate[1]
     }
  
    _startDate=`${__startDate[2]}-${obj.start.month}-${obj.start.day}`
    _endDate=`${__endDate[2]}-${obj.end.month}-${obj.end.day}`
   
    if (content?.schedule?.scheduled) {
      setIsScheduled(true);
      // console.log("inside condition=>", info);
    }
    setPlaylistId(playlistid);
    setContentID(contentid);
    setStartDate(_startDate);
    setEndDate(_endDate);
    setStartTime(content?.schedule?.startTime);
    setEndTime(content?.schedule?.endTime);
    setOpenSchedule(true);
    const dbDay = content?.schedule?.days;
    // console.log(dbDay)
    const sameArray = days.filter(
      (item) => !dbDay.find((d) => d.day === item.day)
    );
    // console.log([...sameArray,...dbDay]);
    setDays([...sameArray, ...dbDay]);
    setDay([...dbDay]);
  };
const cleaner=()=>{
  setDay([]);
  setDays([
    { _id: 1, day: "Mon", dayChecked: false },
    { _id: 2, day: "Tue", dayChecked: false },
    { _id: 3, day: "Wed", dayChecked: false },
    { _id: 4, day: "Thu", dayChecked: false },
    { _id: 5, day: "Fri", dayChecked: false },
    { _id: 6, day: "Sat", dayChecked: false },
    { _id: 7, day: "Sun", dayChecked: false },
  ]);
  setOpenSchedule(false);
  setIsScheduled(false);
}
  const saveSchedule = () => {
    // console.log(day,playlistId,contentID);
    dispatch(
      updatePlaylist(playlistId, contentID, {
        startDate,
        endDate,
        startTime,
        endTime,
        days: day,
      })
    );
   cleaner();
  };
  const deleteScheduleFromPlaylist = (pid, cid) => {
    cleaner();
    dispatch(deletePlaylistSchedule(pid, cid));
  };
  const cancelSchedule = () => {
    cleaner();
  };
  const handleDayCheckbox = (e) => {
    const { name, checked, value } = e.target;
    // console.log(name,checked,value,days)
    if (checked) {
      setDay((prev) => [...prev, { day: value, dayChecked: true }]);
      const selectedDayIndex = days.findIndex((d) => d._id == name);
      days[selectedDayIndex] = { ...days[selectedDayIndex], dayChecked: true };
      setDays([...days]);
      // console.log(selectedDayIndex)
    } else {
      setDay((prev) => [...day.filter((d) => d.day !== value)]);
      const selectedDayIndex = days.findIndex((d) => d._id == name);
      days[selectedDayIndex] = { ...days[selectedDayIndex], dayChecked: false };
      // console.log(selectedDayIndex)
      setDays([...days]);
    }
    // console.log('days array=>',days)
  };

  const onEditFolder = (id) => {
    setFolderIds(id);
    setIsEditMode(true);
    setIsFolderOpen(true);
  };
  return (
    <>
      <Layout title={"Playlist"} user={user}>
        <div className="parent-container">
          <div className="child-container">
            <Grid container>
              <Box className="control-area">
                <Grid lg={8}>
                  <Box
                    sx={{
                      background: "white",
                      padding: "33px 0",
                      marginRight: "20px !important",
                      borderRadius: "16px",
                    }}
                  >
                    <PrimarySearchBar
                      value={textVal}
                      onChange={searchHandler}
                    />
                  </Box>
                </Grid>
                <Grid lg={4} display={"flex"} justifyContent={"flex-end"}>
                  <div className="user-add-btn">
                    <button 
                    // disabled={id !== "Home" ? false : true}
                    onClick={() => setOpenModel(true)}>
                      Create Playlist
                    </button>
                  </div>
                  {/* <div className="user-add-btn">
                  <button className='playlist-folder-btn' 
                   disabled={id == "Home" ? false : true}
                  onClick={() => setIsFolderOpen(true)}
                  >
                    <AddIcon /> Add Folder
                  </button>
                </div> */}
                </Grid>
              </Box>
            </Grid>
          </div>

          {/* {user.role != "superadmin" && (
            <FolderWrapper>
              {folders?.map((_folder, index) => (
                <FolderComponent
                  key={index}
                  index={index}
                  _folder={_folder}
                  _module={"playlist"}
                  onEdit={onEditFolder}
                />
              ))}
            </FolderWrapper>
          )} */}

          <Grid container>
            {
              // id=='Home' ? <></> 
              // :
            <div className="playlist-container">
              {isPlaylistLoading ? (
                <>
                  <Loader />
                </>
              ) : filtered.length >= 0 && (
                filtered.map((play,index) => (
                  <>
                    <PlaylistCard
                      play={play}
                      socketRef={socketRef}
                      addContentModel={addContentModel}
                      deleteWholePlaylist={deleteWholePlaylist}
                      // addSchedule={addSchedule}
                      key={index}
                          //content={v}
                          deleteContent={deleteContent}
                          addSchedule={addSchedule}
                          updateTimeHandler={updateTimeHandler}
                          playID={play._id}
                    >
                     
                    </PlaylistCard>
                  </>
                ))
              ) 
              
              }
            </div>

            }
          </Grid>
        </div>
      </Layout>

      <FolderModel
        open={isFolderOpen}
        module={"playlist"}
        onClose={() => {
          setIsFolderOpen(false);
          setIsEditMode(false);
        }}
        parent={id == "" ? "home" : id}
        userId={user?.role == "user-admin" ? user?.parentId?._id : user?._id}
        isEditMode={isEditMode}
        folderId={_folderId}
      />


      <Dialog
        sx={{ zIndex: "10000000000000" }}
        open={openSchedule}
        onClose={cancelSchedule}
       fullWidth
      >
        <DialogTitle
          style={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "700",
            marginBottom: "30px",
            // paddingBottom: '18px',
            borderBottom: "3px solid var(--primary-color)",
          }}
        >
          <ScheduleSendIcon sx={{ color: "var(--primary-color)" }} /> Schedule
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              minHeight: "50vh",
              width: {xl:"30dvw",lg:'30dvw',md:'30dvw',sm:'100dvw',xs:'100dvw'},
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: "97%",
                width: "97%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  height: "20%",
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                  border: "1px solid transparent",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <InputLabel htmlFor="start-date">Start Date</InputLabel>
                  <TextField
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    id={"start-date"}
                    type={"date"}
                    fullWidth
                  />
                </Box>

                <Box sx={{ flex: 1 }}>
                  <InputLabel htmlFor="end-date">End Date</InputLabel>
                  <TextField
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    id={"end-date"}
                    type={"date"}
                    fullWidth
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  height: "20%",
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                  border: "1px solid transparent",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <InputLabel htmlFor="start-time">Start Time</InputLabel>
                  <TextField
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    id={"start-time"}
                    type={"time"}
                    fullWidth
                  />
                </Box>

                <Box sx={{ flex: 1 }}>
                  <InputLabel htmlFor="end-time">End Time</InputLabel>
                  <TextField
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    id={"end-time"}
                    type={"time"}
                    fullWidth
                  />
                </Box>
              </Box>

              <Box
                id={"days-id"}
               className="days-wrapper"
              >
                {days.map((d, index) => {
                  return (
                    <>
                      <input
                        onChange={handleDayCheckbox}
                        name={d._id}
                        value={d.day}
                        checked={d.dayChecked ? true : false}
                        type={"checkbox"}
                        id={`day-${index}`}
                        style={{ display: "none" }}
                      />
                      <label
                        htmlFor={`day-${index}`}
                        className="label-btn"
                      >
                        {d.day}
                      </label>
                    </>
                  );
                })}
              </Box>

              <Box
                sx={{
                  minHeight: "20%",
                  margin: "10px 0",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                <Button
                  variant={"outlined"}
                  type="button"
                  sx={{
                    width: "100px",
                    color: "var(--primary-color)",
                    border: "1px solid var(--primary-color)",
                  }}
                  onClick={cancelSchedule}
                >
                  Cancel
                </Button>
                <Button
                  variant={"contained"}
                  type="button"
                  className="schedule-save-btn"
                  onClick={saveSchedule}
                >
                  Save
                </Button>
                {isScheduled ? (
                  <>
                    <Button
                      variant="contained"
                      color={"error"}
                      onClick={() =>
                        deleteScheduleFromPlaylist(playlistId, contentID)
                      }
                    >
                      Delete
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
      fullWidth
        sx={{
          maxWidth: "100%",
          zIndex: "1000000000000",
        }}
        open={openModel}
        onClose={() => setOpenModel(false)}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "700",
            marginBottom: "30px",
            // paddingBottom: '18px',
            borderBottom: "3px solid var(--primary-color)",
          }}
        >
          Create Playlist
        </DialogTitle>
        <DialogContent>
          <div className="create-playlist-model flex flex-column">
            <PlaylistAddOutlined
              sx={{
                fontSize: {xl:"100px",lg:'100px',md:'100px',sm:'50px',xs:'50px'},
                color: "var(--primary-color)",
                margin: "0 auto",
              }}
            />
            <div className="create-playlist-input-field">
              <TextField
                variant={"standard"}
                value={name}
                onChange={(e) => setName(e.target.value)}
                label="Type Playlist Name"
                fullWidth
              />
            </div>
            <div
              style={{
                marginTop: "30px",
              }}
              className="create-playlist-btn-group flex justifyContentCenter alignItemCenter"
            >
              <button
                onClick={() => setOpenModel(false)}
                className="btn-cancel"
              >
                Cancel
              </button>
              <button onClick={submit} className="btn-save">
                Save
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth={"xl"}
        open={openContentModel}
        onClose={() => setOpenContentModel(false)}
        sx={{
          zIndex: "10000000000",
        }}
      >
        <DialogTitle
          style={{
            borderBottom: "5px solid var(--primary-color)",
            textAlign: "center",
          }}
        >
          Add Content
        </DialogTitle>
        <DialogContent>
          {/* <Box sx={{width:'90vw'}}> */}

          <Tab
            pId={pId}
            screenMac={screenMac}
            socketRef={socketRef}
            close={(a) => setOpenContentModel(a)}
          />
          {/* </Box> */}
          {/* <div className="main-tab-container">
            
          </div> */}
        </DialogContent>
      </Dialog>

      <Dialog
    
        sx={{
          // maxWidth: '20vw',
          // height: '30vh'
          zIndex: "100000000",
        }}
        open={openTimeDialoge}
        onClose={() => setOpenTimeDialoge(false)}
        fullWidth
      >
        <DialogTitle
          sx={{
            fontWeight: "700",
            fontSize: "18px",
            borderBottom: "2px solid var(--primary-color)",
            mb: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          Time Duration
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              gap: "2px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width:{xl:'30dvw',lg:'30dvw',md:'30dvw',sm:'50dvw',xs:'50dvw'}
            }}
          >
            <Typography
              sx={{
                color: "#92959E",
              }}
            >
              Are you sure to Update Time?
            </Typography>

            <Box sx={{ display: "flex", mt: 5, width: "90%" }}>
              <TextField
                variant={"standard"}
                type="number"
                value={sec}
                onChange={(e) => setSec(e.target.value)}
                fullWidth
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                mt: 5,
                width:'90%'
              }}
            >
              <button
                
                onClick={() => setOpenTimeDialoge(false)}
                className="btn danger btn-no-time-duration"
              >
                No
              </button>
              <button
                onClick={updateTimeConfirm}
                className="btn primary btn-update-time-duration"
              >
                Update
              </button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        sx={{ zIndex: "1000000" }}
        open={openDeleteModel}
        onClose={() => setOpenDeleteModel(false)}
      >
        <DialogTitle style={{ textAlign: "center" }}>Confirmation</DialogTitle>
        <DialogContent style={{ borderRadius: "50px" }}>
          <div
            style={{
              display: "flex",
              gap: "2px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3>Do you want to Delete?</h3>
            <div className="btn-group flex">
              <button
                onClick={() => setOpenDeleteModel(false)}
                className="btn-cancel"
              >
                Cancel
              </button>
              <button onClick={confirmDeletePlaylist} className="btn-save">
                Yes
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Playlist;
