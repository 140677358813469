import { api } from "../../api/api";
import {
  CLEAR_PACKET_STATE,
  CREATE_PACKET_REQUEST,
  CREATE_PACKET_SUCCESS,
  GET_PACKET_REQUEST,
  GET_PACKET_SUCCESS,
  PACKET_DELETE_REQUEST,
  PACKET_DELETE_SUCCESS,
  PACKET_UPDATE_REQUEST,
  PACKET_UPDATE_SUCCESS,
} from "../constant";

export const clearPacketState = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_PACKET_STATE, payload: "" });
  };
};

export const createPacket = (obj) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_PACKET_REQUEST,
      payload: "",
    });
    const { status, data } = await api.post(`/packet/createPacket`, obj);
    if (status == 201) {
      dispatch({
        type: CREATE_PACKET_SUCCESS,
        payload: data.packet,
      });
    }
  };
};
export const getPackets = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_PACKET_REQUEST,
      payload: "",
    });
    const { status, data } = await api.get("/packet/getPackets");
    if (status == 200) {
      dispatch({
        type: GET_PACKET_SUCCESS,
        payload: data.packets,
      });
    }
  };
};
export const deletePacket=(id)=>{
  return async (dispatch)=>{
    dispatch({
      type:PACKET_DELETE_REQUEST,
      payload:''
    })
    const {status,data}=await api.get(`/packet/deletePacket/${id}`);
    if(status==200){
      dispatch({
        type:PACKET_DELETE_SUCCESS,
        payload:id
      })
    }
  }
}
export const updatePacket=(id,obj)=>{
   return async (dispatch)=>{
    dispatch({
      type:PACKET_UPDATE_REQUEST,
      payload:''
    })
    const {status,data}=await api.patch(`/packet/updatePacket/${id}`,obj);
    if(status==201){
      dispatch({
        type:PACKET_UPDATE_SUCCESS,
        payload:data.packet
      })
    }
   }
}