import {
  DeleteOutlineOutlined,
  Edit,
  Folder,
  MoreVert,
} from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./folder.css";
import { Menu, MenuItem } from "@mui/material";
function FolderComponent({ index, _folder, _module, onEdit }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type, obj) => {
    setAnchorEl(null);
  };

  return (
    <>
      {
        <div
          onDoubleClick={() => navigate(`/${_module}/${_folder?._id}`)}
          key={index}
          className="folder"
        >
          <MoreVert
            className="icon-property"
            onClick={handleClick}
            aria-controls={open ? "content-menu" : undefined}
            aria-haspopup={"true"}
            aria-expanded={open ? "true" : undefined}
            id="content-menu-btn"
          />
          <Menu
            // sx={{ zIndex: "10000000" }}
            id={"content-menu"}
            anchorEl={anchorEl}
            open={open}
            MenuListProps={{
              "aria-labelledby": "content-menu-btn",
            }}
            onClose={handleClose}
            keepMounted
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Edit
                onClick={() => onEdit(_folder?._id)}
                sx={{ color: "var(--primary-color)" }}
              />
            </MenuItem>
            {/* <MenuItem
             
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DeleteOutlineOutlined sx={{ color: "var(--primary-color)" }} />
            </MenuItem> */}
          </Menu>
          {/* <div className="menu-icon" onClick={()=>alert('hello')}>
          </div> */}
          <Folder fontSize={"large"} />{" "}
          <span>{_folder?.name?.substring(0, 9)}</span>
        </div>
      }
    </>
  );
}

export default FolderComponent;
