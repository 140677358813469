import {
  Checkbox,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  InputLabel,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import LinkIcon from "@mui/icons-material/Link";
import AddIcon from "@mui/icons-material/Add";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import SearchIcon from "@mui/icons-material/Search";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import "./content.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SlowMotionVideoOutlinedIcon from "@mui/icons-material/SlowMotionVideoOutlined";
import {
  addContent,
  deleteContent,
  checkAllContent,
  unCheckAllContent,
  checkOneContent,
  unCheckOneContent,
  deleteAllContent,
  getContent,
  contentFlagOff,
  deleteContentSchedule,
  addContentSchedule,
  contentUpdate,
  getContentOnFolder,
} from "../../Redux/Actions/contentAction";
import {
  AppsOutlined,
  CloudCircleOutlined,
  LanguageOutlined,
  LockClockOutlined,
  PanoramaHorizontalSelectOutlined,
  PhotoCameraBackOutlined,
} from "@mui/icons-material";
import { imgUrl } from "../../api/api";
import Image from "./component/image";
import Vedio from "./component/vedio";
import Url from "./component/url";
import PrimarySearchBar from "../../components/searchBar/searchbar";
import { Box } from "@mui/system";
import { ReactComponent as VideoIcon } from "../../Assets/images/videoIcon.svg";
import { ReactComponent as ImageIcon } from "../../Assets/images/imageIcon.svg";
import { ReactComponent as UrlIcon } from "../../Assets/images/urlIcon.svg";
import DeleteModel from "../../components/deletemodel/DeleteModel";
import toast from "react-hot-toast";
import { getPlaylist } from "../../Redux/Actions/playlistAction";
import { getScreens } from "../../Redux/Actions/screenAction";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import Pdf from "./component/Pdf";
import Loader from "../../components/loader/Loader";
import AppWidget from "./component/models/AppWidget";
import YoutubeWidget from "./widgets/youtube/YoutubeWidget";
import EmbedWidget from "./widgets/embed/EmbedWidget";
import AppWidgetForm from "./component/models/AppWidgetForm";
import WeatherWidget from "./widgets/weather/WeatherWidget";
import YoutubePlaylist from "./widgets/youtubeplaylist/YoutubePlaylist";
import Widget from "./component/Widget";
import useFilter from "../../hooks/useFilter";
import FolderModel from "../../components/models/FolderModel";
import { useParams } from "react-router-dom";
import FolderWrapper from "../../components/folder/FolderWrapper";
import FolderComponent from "../../components/folder/FolderComponent";
import Clock from "./widgets/clock/Clock";

const appCompnent = {
  default: "",
  youtube: YoutubeWidget,
  youtubeplaylist: YoutubePlaylist,
  embed: EmbedWidget,
  weather: WeatherWidget,
};
function Content({ socketRef, user }) {
  // const navigate = useNavigate();
  const { _id } = useParams();
  const id = _id == null ? "Home" : _id;
  const dispatch = useDispatch();
  const {
    _content,
    isContentAdding,
    isContentScheduled,
    isContentScheduledDeleted,
    isContentLoading,
    isContentAdded,
    isDeleted,
  } = useSelector((state) => state.Content);
  const { _folders, folders } = useSelector(
    (state) => ({
      folders: state.Folder.folders.filter(
        (f) => f.module == "content" && f.parent == id
      ),
      _folders: state.Folder.folders.filter((f) => f.module == "content"),
      // screens: state.Screen.screens,
    }),
    shallowEqual
  );
  const [clockForm,setClockForm]=useState(false);
  const [weatherForm, setWeatherForm] = useState(false);
  const [openMediaPop, setOpenMediaPop] = useState(false);
  const [openEditUrlModel, setOpenEditUrlModel] = useState(false);
  const [ids, setIds] = useState("");
  const [urlContentForm, setUrlContentForm] = useState(false);
  const [urlName, setUrlName] = useState("");
  const [pdfInput, setPdfInput] = useState({
    thumbnail: "",
    thumbnailFile: "",
    pdfThumbnail: "",
    pdfFile: "",
  });
  const [weatherData,setWeatherData]=useState({
    type:'weather',
    title:'',
    link:''
  })
  // const [clockData,setClockData]=useState({
  //   type:'clock',
  //   title:'',
  //   link:''
  // })
  const [isFolderOpen,setIsFolderOpen]=useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [_folderId, setFolderIds] = useState("");
  const [urlContent, setUrlContent] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [contentDeleteId, setContentDeleteId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [contentID, setContentID] = useState("");
  const [filteredContent, setFilteredContent] = useState([]);
  const [openSchedule, setOpenSchedule] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [day, setDay] = useState([]);
  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduleInfo, setScheduleInfo] = useState({});
  const [openWidgets, setOpenWidgets] = useState(false);
  const [openWidgetForm, setOpenWidgetForm] = useState(false);
  const [widgetKey, setWidgetKey] = useState("default");
  const AppComponent = appCompnent[widgetKey];
  const filtered = useFilter(searchText, _content)();
  const [days, setDays] = useState([
    { _id: 1, day: "Mon", dayChecked: false },
    { _id: 2, day: "Tue", dayChecked: false },
    { _id: 3, day: "Wed", dayChecked: false },
    { _id: 4, day: "Thu", dayChecked: false },
    { _id: 5, day: "Fri", dayChecked: false },
    { _id: 6, day: "Sat", dayChecked: false },
    { _id: 7, day: "Sun", dayChecked: false },
  ]);
  useEffect(() => {
    // dispatch(getContent(user?._id))
    socketRef.current.emit("leaving--connection");
    // dispatch(getPlaylist(user?._id));
    // dispatch(getScreens(user?._id));
    // dispatch(getContent(user?._id));
  }, []);
  useEffect(() => {
    if (isContentAdded) {
      setFilteredContent([..._content]);
      dispatch(contentFlagOff());
    }
    if (isDeleted) {
      setFilteredContent([..._content]);
      dispatch(contentFlagOff());
    }
    if (isContentScheduled) {
      toast.success("Playlist have scheduled");
      setIsScheduled(false);
      // const _checkingScreenAssing = screens.find(
      //   (s) => s.playlist?._id === playlistId
      // );
      // if (_checkingScreenAssing !== undefined) {
      //   emitOnScheduleAdd(_checkingScreenAssing.mac);
      // }
      dispatch(contentFlagOff());
    }
    if (isContentScheduledDeleted) {
      setOpenSchedule(false);
      toast.success("schedule deleted successfully");
      setIsScheduled(false);
      // const __checkingScreenAssing = screens.find(
      //   (s) => s.playlist?._id === playlistId
      // );
      // if (__checkingScreenAssing !== undefined) {
      //   emitOnScheduleAdd(__checkingScreenAssing.mac);
      // }
      dispatch(contentFlagOff());
    }
  }, [
    isContentAdded,
    isDeleted,
    isContentScheduled,
    isContentScheduledDeleted,
  ]);
  useEffect(() => {
    if (id !== "Home") {
      dispatch(getContentOnFolder(id));
    }
  }, [id]);
  const handleClick = () => {
    // 👇️ toggle
    setIsActive((current) => !current);

    // 👇️ or set to true
    // setIsActive(true);
  };

  const fileHandler = (e) => {
    const file = e.target.files[0];
    const form = new FormData();
    form.append("content", file);
    form.append("role", user?.role);
    form.append("userId",user.role=='user-admin' ? user?.parentId?._id : user?._id);
    form.append('folderId',_id);
    dispatch(addContent(form));
    setOpenMediaPop(false);
  };
  const urlContentSubmit = () => {
    const form = new FormData();
    form.append("name", urlName);
    form.append("contenttype", "url");
    form.append("url", urlContent);
    form.append("role", user?.role);
    form.append("userId",user.role=='user-admin' ? user?.parentId?._id : user?._id);
    form.append('folderId',_id);
    // form.append("subuser", user?._id);
    dispatch(addContent(form));
    setOpenMediaPop(false);
  };
  const onWeatherSubmit = () => {
    const form = new FormData();
    form.append("name", weatherData.title);
    form.append("contenttype", weatherData.type);
    form.append("url", weatherData.link);
    form.append("role", user?.role);
    form.append("userId",user.role=='user-admin' ? user?.parentId?._id : user?._id);
    form.append('folderId',_id);
    // form.append("subuser", user?._id);
    dispatch(addContent(form));
    setWeatherForm(false);
    setOpenMediaPop(false);
  };
  const onClockSubmit=(clockData)=>{
    const form = new FormData();
    form.append("name", clockData.title);
    form.append("contenttype", clockData.type);
    form.append("url", clockData.link);
    form.append('mDialColor',clockData.mDialColor);
    form.append('mPointColor',clockData.mPointColor);
    form.append('mHourColor',clockData.mHourColor);
    form.append('mMinuteColor',clockData.mMinuteColor);
    form.append('mSecondColor',clockData.mSecondColor);
    form.append("role", user?.role);
    form.append("userId",user.role=='user-admin' ? user?.parentId?._id : user?._id);
    form.append('folderId',_id);
    // form.append("subuser", user?._id);
    dispatch(addContent(form));
    setClockForm(false);
    setOpenMediaPop(false);
  }
  const handleDeleteContent = (id) => {
    // console.log(id)
    setOpenDialog(true);
    setContentDeleteId(id);
  };
  const confirmDeleteContent = () => {
    dispatch(deleteContent(contentDeleteId));
    setOpenDialog(false);
  };
  const searchHandler = (e) => {
    setSearchText(e);
  };

  const addSchedule = (contentid) => {
    const info = _content.find((c) => c.id === contentid);
    // console.log('from addSchedule=>',info)
    const __startDate = new Date(info?.schedule?.startDate)
      .toLocaleDateString()
      .split("/");
    const __endDate = new Date(info?.schedule?.endDate)
      .toLocaleDateString()
      .split("/");
    const isStartDateOneDigit = [1, 2, 3, 4, 5, 6, 7, 8, 9].find(
      (digit) => digit == __startDate[0]
    );
    const isEndDateOneDigit = [1, 2, 3, 4, 5, 6, 7, 8, 9].find(
      (digit) => digit == __endDate[0]
    );
    const _startDate = `${__startDate[2]}-${
      isStartDateOneDigit !== undefined
        ? "0" + isStartDateOneDigit
        : __startDate[0]
    }-${__startDate[1]}`.toString();
    const _endDate = `${__endDate[2]}-${
      isEndDateOneDigit !== undefined ? "0" + isEndDateOneDigit : __endDate[0]
    }-${__endDate[1]}`.toString();
    if (info?.schedule?.scheduled) {
      setIsScheduled(true);
      // console.log("inside condition=>", info);
    }
    setContentID(contentid);
    setStartDate(_startDate);
    setEndDate(_endDate);
    setStartTime(info?.schedule?.startTime);
    setEndTime(info?.schedule?.endTime);
    setOpenSchedule(true);
    const dbDay = info.schedule.days;
    // console.log(dbDay)
    const sameArray = days.filter(
      (item) => !dbDay.find((d) => d.day === item.day)
    );
    // console.log([...sameArray,...dbDay]);
    setDays([...sameArray, ...dbDay]);
    setDay([...dbDay]);
  };

  const saveSchedule = () => {
    // console.log(day);
    dispatch(
      addContentSchedule(contentID, {
        startDate,
        endDate,
        startTime,
        endTime,
        days: day,
      })
    );
    setDay([]);
    setOpenSchedule(false);
  };
  const deleteScheduleFromContent = (id) => {
    dispatch(deleteContentSchedule(id));
  };
  const emitOnScheduleAdd = (mac) => {
    const id = setTimeout(() => {
      socketRef.current?.emit("update_duration", { detail: { mac: mac } });
      clearTimeout(id);
    }, 2000);
  };
  const cancelSchedule = () => {
    setOpenSchedule(false);
    // console.log([...days]);
    setDay([]);
    setDays([
      { _id: 1, day: "Mon", dayChecked: false },
      { _id: 2, day: "Tue", dayChecked: false },
      { _id: 3, day: "Wed", dayChecked: false },
      { _id: 4, day: "Thu", dayChecked: false },
      { _id: 5, day: "Fri", dayChecked: false },
      { _id: 6, day: "Sat", dayChecked: false },
      { _id: 7, day: "Sun", dayChecked: false },
    ]);
  };
  const handleDayCheckbox = (e) => {
    const { name, checked, value } = e.target;
    // console.log(name,checked,value,days)
    if (checked) {
      setDay((prev) => [...prev, { day: value, dayChecked: true }]);
      const selectedDayIndex = days.findIndex((d) => d._id == name);
      days[selectedDayIndex] = { ...days[selectedDayIndex], dayChecked: true };
      setDays([...days]);
      // console.log(selectedDayIndex)
    } else {
      setDay((prev) => [...day.filter((d) => d.day !== value)]);
      const selectedDayIndex = days.findIndex((d) => d._id == name);
      days[selectedDayIndex] = { ...days[selectedDayIndex], dayChecked: false };
      // console.log(selectedDayIndex)
      setDays([...days]);
    }
    // console.log('days array=>',days)
  };

  const onEditContent = (_contentData) => {
    //  console.log(_contentData)
    setIds(_contentData.id);
    setUrlName(_contentData.name);
    setUrlContent(_contentData.url);
    setOpenEditUrlModel(true);
  };
  const urlContentUpdate = () => {
    dispatch(contentUpdate(ids, { url: urlContent, name: urlName }));
    setOpenEditUrlModel(false);
  };

  const onOpenWidgetForm = (_key) => {
    setWidgetKey(_key);
    setOpenWidgetForm(true);
    setOpenWidgets(false);
  };
  const onEditFolder = (id) => {
    setFolderIds(id);
    setIsEditMode(true);
    setIsFolderOpen(true);
  };
  return (
    <Layout title="Content" user={user}>
      {/* <iframe width="720" height="405" src="https://www.youtube.com/embed/?listType=playlist&list=PLxf3-FrL8GzTLaDiaApnrXeFVlUoUFjQz" frameborder="0" allowfullscreen></iframe> */}
      <div className="parent-container">
        <div className="child-container">
          <Grid container>
            <Box className={"control-area"}>
              <Grid lg={8}>
                <Box
                  sx={{
                    background: "white",
                    padding: "33px 0",
                    marginRight: "20px !important",
                    borderRadius: "16px",
                  }}
                >
                  <PrimarySearchBar
                    value={searchText}
                    onChange={searchHandler}
                  />
                </Box>
              </Grid>
              <Grid lg={4} display={"flex"} justifyContent={"flex-end"}>
                <div className="user-add-btn">
                  <button 
                  disabled={id !== "Home" ? false : true}
                  onClick={() => setOpenMediaPop(true)}>
                    <AddIcon /> Add Content
                  </button>
                </div>
                <div className="user-add-btn">
                  <button className='content-folder-btn' 
                   disabled={id == "Home" ? false : true}
                  onClick={() => setIsFolderOpen(true)}
                  >
                    <AddIcon /> Add Folder
                  </button>
                </div>
              </Grid>
            </Box>
          </Grid>
        </div>

        {user.role != "superadmin" && (
            <FolderWrapper>
              {folders?.map((_folder, index) => (
                <FolderComponent
                  key={index}
                  index={index}
                  _folder={_folder}
                  _module={"content"}
                  onEdit={onEditFolder}
                />
              ))}
            </FolderWrapper>
          )}

{
  id=='Home' ? <>
  </> : 
<>
{isContentAdding && (
  <>
    <Box
      sx={{
        position: "absolute",
        height: "100vh",
        width: "100vw",
        background: "rgba(0,0,0,0.5)",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: "1000000",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader />
    </Box>
  </>
)}

        <div className="flex flex-wrap justifyContentCenter alignItemCenter margin">
          {filtered.length >= 0 &&
            filtered.map((item, index) => (
              <>
                {item.type === "image" && (
                  <Image
                    key={index}
                    data={item}
                    addSchedule={addSchedule}
                    deleteSingleContent={handleDeleteContent}
                  />
                )}
                {item.type === "video" && (
                  <Vedio
                    key={index}
                    data={item}
                    addSchedule={addSchedule}
                    deleteSingleContent={handleDeleteContent}
                  />
                )}
                {item.type === "url" && (
                  <Url
                    key={index}
                    data={item}
                    addSchedule={addSchedule}
                    deleteSingleContent={handleDeleteContent}
                    editContent={onEditContent}
                  />
                )}
                {item.type === "application" && (
                  <Pdf
                    key={index}
                    data={item}
                    addSchedule={addSchedule}
                    deleteSingleContent={handleDeleteContent}
                  />
                )}
                {(item.type == "weather" || item.type=='clock') && (
                  <Widget
                    key={index}
                    data={item}
                    addSchedule={addSchedule}
                    deleteSingleContent={handleDeleteContent}
                    editContent={onEditContent}
                  />
                )}
              </>
            ))}
        </div>
</>

}

      </div>

      <FolderModel
        open={isFolderOpen}
        module={"content"}
        onClose={() => {
          setIsFolderOpen(false);
          setIsEditMode(false);
        }}
        parent={id == "" ? "home" : id}
        userId={user?.role == "user-admin" ? user?.parentId?._id : user?._id}
        isEditMode={isEditMode}
        folderId={_folderId}
      />


      <Dialog
        sx={{ zIndex: "10000000000000" }}
        open={openSchedule}
        onClose={() => setOpenSchedule(false)}
        maxWidth={"lg"}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "700",
            marginBottom: "30px",
            // paddingBottom: '18px',
            borderBottom: "3px solid var(--primary-color)",
          }}
        >
          <ScheduleSendIcon sx={{ color: "var(--primary-color)" }} /> Schedule
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              height: "50vh",
              width: "30vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: "97%",
                width: "97%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  height: "20%",
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                  border: "1px solid transparent",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <InputLabel htmlFor="start-date">Start Date</InputLabel>
                  <TextField
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    id={"start-date"}
                    type={"date"}
                    fullWidth
                  />
                </Box>

                <Box sx={{ flex: 1 }}>
                  <InputLabel htmlFor="end-date">End Date</InputLabel>
                  <TextField
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    id={"end-date"}
                    type={"date"}
                    fullWidth
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  height: "20%",
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                  border: "1px solid transparent",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <InputLabel htmlFor="start-time">Start Time</InputLabel>
                  <TextField
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    id={"start-time"}
                    type={"time"}
                    fullWidth
                  />
                </Box>

                <Box sx={{ flex: 1 }}>
                  <InputLabel htmlFor="end-time">End Time</InputLabel>
                  <TextField
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    id={"end-time"}
                    type={"time"}
                    fullWidth
                  />
                </Box>
              </Box>

              <Box
                id={"days-id"}
                sx={{
                  minHeight: "20%",
                  margin: "10px 0",
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {days.map((d, index) => {
                  return (
                    <>
                      <input
                        onChange={handleDayCheckbox}
                        name={d._id}
                        value={d.day}
                        checked={d.dayChecked ? true : false}
                        type={"checkbox"}
                        id={`day-${index}`}
                        style={{ display: "none" }}
                      />
                      <label
                        htmlFor={`day-${index}`}
                        style={{
                          cursor: "pointer",
                          borderRadius: "10px",
                          height: "50px",
                          width: "80px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid black",
                        }}
                      >
                        {d.day}
                      </label>
                    </>
                  );
                })}
              </Box>

              <Box
                sx={{
                  minHeight: "20%",
                  margin: "10px 0",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                <Button
                  variant={"outlined"}
                  type="button"
                  sx={{
                    width: "100px",
                  }}
                  onClick={cancelSchedule}
                >
                  Cancel
                </Button>
                <Button
                  variant={"contained"}
                  type="button"
                  sx={{
                    width: "100px",
                  }}
                  onClick={saveSchedule}
                >
                  Save
                </Button>
                {isScheduled ? (
                  <>
                    <Button
                      variant="contained"
                      color={"error"}
                      onClick={() => deleteScheduleFromContent(contentID)}
                    >
                      Delete
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <DeleteModel
        open={openDialog}
        onClose={(e) => setOpenDialog(e)}
        onConfirm={confirmDeleteContent}
      />

      <AppWidget
        maxWidth={"md"}
        isFullWidth={true}
        isOpen={openWidgets}
        onClose={() => setOpenWidgets(false)}
        onOpenWidgetForm={onOpenWidgetForm}
      />

      <AppWidgetForm
        maxWidth={"lg"}
        // isFullWidth={true}
        isOpen={openWidgetForm}
        onClose={() => {
          setOpenWidgetForm(false);
          setOpenWidgets(true);
        }}
      >
        <AppComponent
          onClose={() => {
            setOpenWidgetForm(false);
            setOpenWidgets(true);
          }}
        />
      </AppWidgetForm>

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={openMediaPop}
        onClose={() => setOpenMediaPop(false)}
        sx={{
          zIndex: "100000000",
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderBottom: "3px solid var(--primary-color)",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          Add Content
        </DialogTitle>
        <DialogContent>
          <div className="content-add-media-box">
            <input type="file" hidden id="photo" onChange={fileHandler} />
            <div className="add-media-tab">
              <label htmlFor="photo" className="add-media-label">
                <PhotoCameraBackOutlined className="add-media-icon" />
                <p className="media-text">Image</p>
              </label>
            </div>
            <div className="add-media-tab">
              <label htmlFor="photo" className="add-media-label">
                <SlowMotionVideoOutlinedIcon className="add-media-icon" />

                <p className="media-text">Video</p>
              </label>
            </div>

            <div
              className="add-media-tab add-media-tab-flex"
              onClick={() => setUrlContentForm(!urlContentForm)}
            >
              <LanguageOutlined
                className="add-media-icon"

                // style={{
                //   background: isActive ? 'white' : 'white',
                // }}
              />
              <p
                className="media-text"
                //  style={{
                //   color: isActive ? 'white' : '',
                // }}
                // onClick={handleClick}
              >
                Url
              </p>
            </div>

            <div className="add-media-tab">
              <label htmlFor="photo" className="add-media-label">
                <PictureAsPdfOutlinedIcon className="add-media-icon" />
                <p className="media-text">Pdf</p>
              </label>
            </div>
            <div className="add-media-tab" onClick={()=>{
              setWeatherForm(!weatherForm);
              setClockForm(false);
            }}>
              <label htmlFor="" className="add-media-label">
                <CloudCircleOutlined className="add-media-icon" />
                <p className="media-text">Weather</p>
              </label>
            </div>
            <div className="add-media-tab" onClick={()=>{
              setClockForm(!clockForm);
              setOpenMediaPop(false);
            }}>
              <label htmlFor="" className="add-media-label">
                <LockClockOutlined className="add-media-icon" />
                <p className="media-text">Clock</p>
              </label>
            </div>
          </div>
{/* weather form */}
          {weatherForm && (
            <div style={{ margin: "20px 0" }}>
              <Typography variant={'h6'} textAlign='center'>Weather Widget Form</Typography>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  variant="standard"
                  name={'title'}
                  value={weatherData.title}
                  onChange={(e) => setWeatherData((prev)=>({...prev,[e.target.name]:e.target.value}))}
                  label="Title"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                  required
                />
                <TextField
                  variant="standard"
                  required
                  name={'link'}
                  value={weatherData.link}
                  onChange={(e) => setWeatherData((prev)=>({...prev,[e.target.name]:e.target.value}))}
                  label="Url Link"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                />
                <button
                  style={{ marginTop: "40px" }}
                  className="btn-save-content"
                  onClick={onWeatherSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          )}

{/* clock form */}

{/* {clockForm && (
            <div style={{ margin: "20px 0" }}>
              <Typography variant={'h6'} textAlign={'center'}>Clock Widget Form</Typography>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                required
                  variant="standard"
                  name={'title'}
                  value={clockData.title}
                  onChange={(e) => setClockData((prev)=>({...prev,[e.target.name]:e.target.value}))}
                  label="Title"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                />
                <TextField
                required
                  variant="standard"
                  name={'link'}
                  value={clockData.link}
                  onChange={(e) => setClockData((prev)=>({...prev,[e.target.name]:e.target.value}))}
                  label="Url Link"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                />
                <button
                  style={{ marginTop: "40px" }}
                  className="btn-save-content"
                  onClick={onClockSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          )} */}

{/* url form */}

          {urlContentForm && (
            <div style={{ margin: "20px 0" }}>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <TextField
                  variant="standard"
                  value={urlName}
                  onChange={(e) => setUrlName(e.target.value)}
                  label="Title"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                />
                <TextField
                  variant="standard"
                  value={urlContent}
                  onChange={(e) => setUrlContent(e.target.value)}
                  label="Url Link"
                  fullWidth
                  sx={{
                    label: {
                      color: "blue",
                      fontSize: "14px",
                      color: "#92959E",
                    },
                  }}
                />
                <button
                  style={{ marginTop: "40px" }}
                  className="btn-save-content"
                  onClick={urlContentSubmit}
                >
                  Save
                </button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>

<Clock
open={clockForm}
onClose={()=>setClockForm(false)}
onClockSubmit={onClockSubmit}
/>

      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={openEditUrlModel}
        onClose={() => setOpenEditUrlModel(false)}
      >
        <DialogTitle>Url Edit</DialogTitle>
        <DialogContent>
          <div style={{ margin: "20px 0" }}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                variant="standard"
                value={urlName}
                onChange={(e) => setUrlName(e.target.value)}
                label="Title"
                fullWidth
                sx={{
                  label: {
                    color: "blue",
                    fontSize: "14px",
                    color: "#92959E",
                  },
                }}
              />
              <TextField
                variant="standard"
                value={urlContent}
                onChange={(e) => setUrlContent(e.target.value)}
                label="Url Link"
                fullWidth
                sx={{
                  label: {
                    color: "blue",
                    fontSize: "14px",
                    color: "#92959E",
                  },
                }}
              />
              <button
                style={{ marginTop: "40px" }}
                className="btn-save-content"
                onClick={urlContentUpdate}
              >
                Update
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </Layout>
  );
}

export default Content;
