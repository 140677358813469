import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Input,
  TextField,
  FormControl,
  ListItemIcon,
  Drawer,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DonutChart from "../../components/charts/DonutChart";
import Layout from "../../components/layout/Layout";
import {
  addClockWidget,
  addPlaylistToScreen,
  addWeatherWidget,
  assignMultiplePlaylistToScreen,
  screenFlagOff,
  uncheckAssignedScreen,
} from "../../Redux/Actions/screenAction";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import "./setting.css";
import { ReactComponent as ScreenIcon } from "../../Assets/images/screenIcon.svg";
import { ReactComponent as UserProfileIcon } from "../../Assets/images/userprofileIcon.svg";
import { ReactComponent as MobileImage } from "../../Assets/images/mobileImage.svg";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import { TextareaAutosize } from "@material-ui/core";
import { playlistFlagOff } from "../../Redux/Actions/playlistAction";
import { Check, Close, DesktopMac } from "@mui/icons-material";
import ListIcon from "@mui/icons-material/List";
import { MultiSelect } from "react-multi-select-component";
import useFilter from "../../hooks/useFilter";
import { api } from "../../api/api";
import { UPDATE_SCREEN_SUCCESS } from "../../Redux/constant";
import toast from "react-hot-toast";
function Setting({ user, socketRef }) {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { screens, isUnChecked, isScreenUpdated } = useSelector(
    (state) => state.Screen
  );
  const { playlist } = useSelector((state) => state.Playlist);
  const {packets}=useSelector(state=>state.Packet);
  const [searchVal, setSearchVal] = useState("");
  const [isScreenOpen, setIsScreenOpen] = useState(false);
  const [playlists, setPlaylists] = useState([]);
  const [playlistInfo, setPlaylistInfo] = useState({});
  const [code, setCode] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [message, setMessage] = useState("");
  const [duration, setDuration] = useState(60);
  const [instantEvent, setInstantEvent] = useState("");
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const macRef = useRef(null);
  const selRef = useRef(null);
  const filtered = useFilter(searchVal, playlists)();

  // useEffect(() => {
  //   if(isScreenOpen){
  //     if(selected.length == 2){
  //       // alert('not allowed')
  //       setPlaylists((prev) => [
  //         ...prev.map((s) => (s.isChecked == false ? { ...s,disable:true } : s)),
  //       ]);
  //     }else{
  //       // alert('allowed')
  //       setPlaylists((prev) => [
  //         ...prev.map((s) => (s.isChecked == false ? { ...s,disable:false } : s)),
  //       ]);
  //     }
  //   }
  // }, [isScreenOpen,selected]);
  useEffect(() => {
    // console.log('from use Effect->',screens)
    setPlaylistInfo({
      ...screens.find((s) => s._id === params.id),
      screenAssigned: false,
    });
  }, [params.id]);
  useEffect(() => {
    if (isUnChecked) {
      setPlaylistInfo({ ...screens.find((s) => s._id === params.id) });
      dispatch(screenFlagOff());
    }
    if (isScreenUpdated) {
      setPlaylistInfo({ ...screens.find((s) => s._id === params.id) });
      socketRef.current?.emit("event:delegate", {
        detail: { mac: macRef?.current?.value },
      });
      dispatch(screenFlagOff());
      toast.success("Screen updated.");
      setSelected([]);
      setIsScreenOpen(false);
    }
  }, [isUnChecked, isScreenUpdated]);
  useEffect(() => {
    let opt = [];
    playlist.forEach((p) => {
      opt.push({ label: p.name, value: p._id });
    });
    setOptions(opt);
    socketRef.current.emit("leaving--connection");
    socketRef.current.on("screen--connection--off", (obj) => {
      //  console.log(obj)
    });
  }, []);
  const submit = () => {
    socketRef?.current.emit("message", {
      message,
      duration,
      mac: playlistInfo.mac,
    });
    setOpenModel(false);
    setMessage("");
  };
  const emitAssignPlaylistToScreen = (c) => {
    const id = setTimeout(() => {
      const screen = screens.find((s) => s._id === c);
      // console.log(screen);
      if (screen !== null) {
        socketRef.current?.emit("event:delegate", {
          detail: { mac: screen?.mac, code: c },
        });
        clearTimeout(id);
      }
    }, 2000);
  };
  const screenHanlderSelect = (e) => {
    setCode(e.target.value);
    const mac = screens.find((c) => c._id === params.id);
    // console.log(e.target.value);
    dispatch(
      addPlaylistToScreen({
        id: params.id,
        value: e.target.value,
        mac: mac.mac,
      })
    );
    // setPlaylistInfo({...screens.find(s=>s._id===params.id),screenAssigned:true})
    emitAssignPlaylistToScreen(params.id);
  };
  const onSearchFilter = (e) => {
    const { value } = e.target;
    setSearchVal(value);
  };
  const searchCallBack = useCallback(onSearchFilter, [searchVal]);

  const onGetScreenAssign = useCallback((e, _s) => {
    const { value, checked, name } = e.target;

    if (checked) {
      setPlaylists((prev) => [
        ...prev.map((s) =>
          s.name == name ? { ...s, isChecked: true, disable: false } : s
        ),
      ]);

      setSelected((prev) => [...prev, value]);
    } else {
      setPlaylists((prev) => [
        ...prev.map((s) =>
          s.name == name ? { ...s, isChecked: false, disable: false } : s
        ),
      ]);

      setSelected((prev) => prev.filter((f) => f != value));
    }

    // }
  }, []);

  const onAssignPlaylist = async () => {
    try {
      // let mac_list = [];
      // for (let i in filtered) {
      //   if (filtered[i].isChecked) {
      //     mac_list.push(filtered[i].id);
      //   }
      // }
      dispatch(assignMultiplePlaylistToScreen(params.id, selected));
    } catch (error) {
      console.log(error.message);
    }
  };
  const onSelectPlaylist = useCallback(() => {
    let pl = [];
    let playlistObject = {};
    if (Object.keys(playlistObject).length == 0) {
      screens[0]._playlist.forEach((e) => {
        if (Object.hasOwnProperty(e._id)) {
        } else {
          setSelected((prev) => [...prev, e._id]);
          playlistObject[e._id] = e.name;
        }
      });
      playlist.forEach((p) => {
        if (playlistObject[p._id] == p.name) {
          pl.push({ id: p._id, name: p.name, isChecked: true });
        } else {
          pl.push({ id: p._id, name: p.name, isChecked: false });
        }
      });

      setPlaylists(pl);
    }
    setIsScreenOpen(true);
  }, []);
  const onAddClock = async (e) => {
    try {
      const { checked } = e.target;
      if (checked) {
        dispatch(addClockWidget(params.id, checked));
      } else {
        dispatch(addClockWidget(params.id, checked));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const onAddWeather = async (e) => {
    try {
      const { checked } = e.target;
      if (checked) {
        dispatch(addWeatherWidget(params.id, checked));
      } else {
        dispatch(addWeatherWidget(params.id, checked));
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  // console.log(selected)
  return (
    <>
      <input hidden ref={macRef} value={playlistInfo?.mac} />
      <input hidden ref={selRef} value={selected.length} />
      <Layout title={"Setting"} user={user}>
        <Grid container>
          <Grid item lg={8} xs={12}>
            <Box
              sx={{
                background: "white",
                p: 2,
                margin: "18px 20px",
                borderRadius: "16px",
              }}
            >
              <div className="device flex flex-column">
                <div className="device-header flex flex-content-space-around alignItemCenter">
                  <div className="device-header-left flex">
                    <div className="screen-icon flex justifyContentCenter alignItemCenter">
                      <MobileFriendlyIcon
                        style={{ color: "var(--primary-color)" }}
                      />
                      {/* <img src={"/images/MobileScreen.png"} /> */}
                    </div>
                    <div className="screen-text flex flex-column">
                      <p>{playlistInfo?.device_details?.device_name}</p>
                      {playlistInfo?.onlinesStatus ? (
                        <span>Connected</span>
                      ) : (
                        <span>Disconnected</span>
                      )}
                    </div>
                  </div>
                  {/* <div style={{}}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      {playlistInfo.isClock ? (
                        <span>
                          <Check /> Clock
                        </span>
                      ) : (
                        <span>
                          {" "}
                          <Close />
                          Clock
                        </span>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      {playlistInfo.isWeather ? (
                        <span>
                          <Check /> Weather
                        </span>
                      ) : (
                        <span>
                          {" "}
                          <Close /> Weather
                        </span>
                      )}
                    </div>
                  </div> */}
                  <div className="device-header-right flex">
                    <div className="playlist-selector flex flex-column justifyContentCenter alignItemCenter">
                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p>
                          {playlistInfo?._playlist?.map((_, _index) => (
                            <span>{_?.name} , </span>
                          ))}
                        </p>
                        <BorderColorIcon
                          onClick={onSelectPlaylist}
                          style={{ color: "var(--primary-color)" }}
                          // onClick={() =>
                          //   dispatch(uncheckAssignedScreen(playlistInfo._id))
                          // }
                        />
                      </Box>
                    </div>
                  </div>
                </div>
                <Grid container>
                  <Grid item lg={8}>
                    <Grid
                      container
                      sx={{
                        borderBottom: "1px solid rgba(85, 31, 255, 0.2)",
                        pt: 2.5,
                        pb: 2.5,
                      }}
                    >
                      <Grid item lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            pl: 7,
                          }}
                        >
                          <div className="info-1">
                            <p>Code</p>
                            <span>{playlistInfo.code}</span>
                          </div>
                          <div className="info-2">
                            <p>Device Name</p>
                            <span>
                              {playlistInfo?.device_details?.device_name}
                            </span>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            pl: 7,
                          }}
                        >
                          <div className="info-1">
                            <p>Name</p>
                            <span>{playlistInfo.name}</span>
                          </div>
                          <div className="info-2">
                            <p>Manufacturing</p>
                            <span>
                              {playlistInfo?.device_details?.manufacturer}
                            </span>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      sx={{
                        borderBottom: "1px solid rgba(85, 31, 255, 0.2)",
                        pt: 2.5,
                        pb: 2.5,
                      }}
                    >
                      <Grid item lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 7,
                          }}
                        >
                          <div className="info-1">
                            <p>OS</p>
                            <span>
                              {playlistInfo?.device_details?.os_version}
                            </span>
                          </div>
                          <div className="info-2">
                            <p>Location</p>
                            <span>United State</span>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 7,
                          }}
                        >
                          <div className="info-1">
                            <p>Mac Address</p>
                            <span>{playlistInfo.mac}</span>
                          </div>
                          <div className="info-2">
                            <p>Ip Address</p>
                            <span>{playlistInfo?.device_details?.ip}</span>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      sx={{
                        pt: 2.5,
                        pb: 2.5,
                      }}
                    >
                      <Grid item lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 7,
                          }}
                        >
                          <div className="info-1">
                            <p>Software</p>
                            <span>
                              {playlistInfo?.device_details?.software}
                            </span>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 7,
                          }}
                        >
                          <div className="info-1">
                            <p>Root Level</p>
                            <span>
                              {playlistInfo?.device_details?.root_level}
                            </span>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 7,
                          }}
                        >
                          <div className="info-1">
                            <p></p>
                            <span>
                              <button
                                onClick={() => setOpenDrawer(true)}
                                style={{
                                  background: "var(--primary-color)",
                                  color: "white",
                                  width: "150px",
                                  height: "30px",
                                  cursor: "pointer",
                                  borderRadius: "5px",
                                }}
                              >
                                Remote controll
                              </button>
                            </span>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={4}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        color: "#15192C",
                        fontSize: "18px",
                        py: 3,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        Device Details
                      </Typography>
                    </Box>
                    <Box>
                      <div className="ram">
                        <DonutChart
                          views={"Ram"}
                          series={[
                            +playlistInfo?.ram?.total?.split(" ")[0] -
                              +playlistInfo?.ram?.free?.split(" ")[0],
                            +playlistInfo?.ram?.free?.split(" ")[0],
                          ]}
                          labels={[
                            `Usage ${playlistInfo?.ram?.total?.split(" ")[1]}`,
                            `Free ${playlistInfo?.ram?.free?.split(" ")[1]}`,
                          ]}
                        />
                      </div>
                    </Box>
                    <Box>
                      <div className="storage">
                        <DonutChart
                          views={"Storage"}
                          series={[
                            +playlistInfo?.storage?.total?.split(" ")[0] -
                              +playlistInfo?.storage?.free?.split(" ")[0],
                            +playlistInfo?.storage?.free?.split(" ")[0],
                          ]}
                          labels={[
                            `Usage ${
                              playlistInfo?.storage?.total?.split(" ")[1]
                            }`,
                            `Free ${
                              playlistInfo?.storage?.free?.split(" ")[1]
                            }`,
                          ]}
                        />
                      </div>
                    </Box>
                    
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Grid>
          <Grid item lg={4} xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // background: "var(--primary-color)",
                background: "white",
                padding: "15px 20px",
                color: "white",
                margin: "15px",
                borderRadius: "15px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Seect Action
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={instantEvent}
                  onChange={(e) => setInstantEvent(e.target.value)}
                  variant={"outlined"}
                  label="Select Action"
                  sx={{
                    border: "1px solid white",
                    width: "100%",
                    margin: "0 auto",
                  }}
                >
                  <MenuItem
                    value={"Close App"}
                    onClick={() =>
                      socketRef.current.emit("close--app", {
                        mac: playlistInfo?.mac,
                      })
                    }
                  >
                    Close App
                  </MenuItem>
                  <MenuItem
                    value={"aunch App"}
                    onClick={() =>
                      socketRef.current.emit("launch--app", {
                        mac: playlistInfo?.mac,
                      })
                    }
                  >
                    Lauch App
                  </MenuItem>
                  <MenuItem
                    value={"Reset App"}
                    onClick={() =>
                      socketRef.current.emit("reset--app", {
                        mac: playlistInfo?.mac,
                      })
                    }
                  >
                    Reset App
                  </MenuItem>
                  <MenuItem
                    value={"Reload App"}
                    onClick={() =>
                      socketRef.current.emit("reload--app", {
                        mac: playlistInfo?.mac,
                      })
                    }
                  >
                    Reload App
                  </MenuItem>
                  <MenuItem
                    value={"Refresh App"}
                    onClick={() =>
                      socketRef.current.emit("refresh--app", {
                        mac: playlistInfo?.mac,
                      })
                    }
                  >
                    Refresh App
                  </MenuItem>
                </Select>
              </FormControl>
              {/* <UserProfileIcon />
              <Typography
                sx={{
                  fontSize: "12px",
                  pl: 1,
                }}
              >
                User profile settings
              </Typography> */}
            </Box>
            <Box
              sx={{
                background: "white",
                p: 2,
                margin: "18px 20px",
                borderRadius: "16px",
              }}
            >
              <MobileImage />
              <Box>
                <Typography
                  sx={{
                    color: "#15192C",
                    fontSize: "16px",
                    fontWeight: "bold",
                    mt: 3,
                  }}
                >
                  Detail:
                </Typography>
                <Typography
                  sx={{
                    color: "#92959E",
                    fontSize: "15px",
                  }}
                >
                  Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                  Lorem Ipsum Lorem Ipsum Lorem Ipsum
                </Typography>
                <Button
                  variant={"outlined"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "15px 20px",
                    color: "var(--primary-color)",
                    margin: "15px",
                    borderRadius: "6px",
                    border: " 1px solid var(--primary-color)",
                    mt: 3,
                    fontSize: "15px",
                    pl: 1,
                    width: {
                      xl: "300px",
                      lg: "300px",
                      md: "300px",
                      sm: "200px",
                      xs: "200px",
                    },
                    textTransform: "capitalize",
                  }}
                  onClick={() => setOpenModel(true)}
                >
                  Send Message
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Layout>

      <Dialog
        open={isScreenOpen}
        maxWidth={"sm"}
        onClose={() => {
          setSelected([]);
          setIsScreenOpen(false);
        }}
        sx={{
          zIndex: "100000000000",
        }}
      >
        <DialogTitle display={"flex"} justifyContent={"space-between"}>
          <span>Assign to screens</span>{" "}
          <Close
            onClick={() => {
              setSelected([]);
              setIsScreenOpen(false);
            }}
            sx={{ color: "red", cursor: "pointer" }}
          />{" "}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              height: "5vh",
              width: "100%",
              margin: "10px 0",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {/* <div style={{display:'flex',alignItems:'center',justifyContent:'center',gap:'2px'}}>
            <input type='checkbox' onChange={onAddClock} style={{height: "20px", width: "20px"}}/> Clock
            </div>
            <div style={{display:'flex',alignItems:'center',justifyContent:'center',gap:'2px'}}>
            <input type={'checkbox'} onChange={onAddWeather} style={{height: "20px", width: "20px"}} /> Weather
            </div> */}
            {/* <select
           onChange={onGetFolderScreen} 
          style={{height:'100%',width:'100%'}}>
               {
                playlist?.map((_,_index)=>(<option key={_index} value={_?._id}>{_?.name}</option>))
               }
            </select> */}
          </Box>
          <Box
            sx={{
              height: "5vh",
              width: "100%",
              margin: "10px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              value={searchVal}
              onChange={searchCallBack}
              variant={"outlined"}
              label={"Search..."}
              fullWidth
            />
          </Box>
          <Box
            sx={{
              maxHeight: "40vh",
              width: "20dvw",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              overflowY: "auto",
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
              gap={"10px"}
            ></Box>
            {filtered?.map((_, _index) => (
              <>
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  <input
                    type={"checkbox"}
                    name={_?.name}
                    value={_?.id}
                    onChange={(e) => {
                      onGetScreenAssign(e, selected);
                    }}
                    checked={_.isChecked}
                    disabled={_.disable}
                    style={{ height: "20px", width: "20px" }}
                  />
                  <span>{_?.name}</span>
                </Box>
              </>
            ))}
          </Box>
          <Box
            sx={{
              height: "10vh",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => {
                onAssignPlaylist();
              }}
              variant={"contained"}
              sx={{
                background: "var(--primary-color)",
                textTransform: "capitalize",
              }}
              className="multiple--assign--btn"
            >
              Assign
            </Button>
            {/* {isPlaylistAssign && <MiniLoader />} */}
            {/* <span>Total Screens {screens.length}</span> */}
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        // maxWidth={"xl"}
        fullWidth={true}
        open={openModel}
        onClose={() => setOpenModel(false)}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            borderBottom: "2px solid var(--primary-color)",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          Send Message
        </DialogTitle>
        <DialogContent>
          <div className="screen-form">
            <div className="icon-screen">
              {/* <AddScreenIcon /> */}
              <MailOutlineIcon
                sx={{
                  color: "var(--primary-color)",
                  height: "50px",
                  width: "50px",
                }}
              />
            </div>
            <div className="form-fields">
              <TextField
                type={"text"}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                variant={"outlined"}
                label={"Write Message"}
                multiline
                fullWidth
              />
            </div>

            <div className="form-fields">
              <TextField
                type={"number"}
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                sx={{
                  width: "100px",
                }}
              />
            </div>

            <Box
              sx={{
                display: "flex",
                mt: 8,
                mb: 5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  background: "white",
                  padding: "8px",
                  width: "130px",
                  color: "var(--primary-color)",
                  border: "1px solid var(--primary-color)",
                  marginRight: "10px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => setOpenModel(false)}
              >
                Cancel
              </button>
              <button
                style={{
                  background: "var(--primary-color)",
                  padding: "8px",
                  width: "130px",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={submit}
                className="btn primary"
              >
                Send
              </button>
            </Box>
          </div>
        </DialogContent>
      </Dialog>

      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor={"right"}
      >
        <div style={{ width: "30vw",minHeight:'100vh',display:'flex',flexDirection:'column' }}>

          <div
            style={{
              height: "10vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2>Remote Button</h2>
          </div>

          <div
            style={{
              minHeight: "40vh",
              width: "30vw",
              display: "flex",
              justifyContent: "center",
               alignItems:'center',
              flexWrap: "wrap",
              gap: "10px"
            }}
          >
            {packets?.map((packet) => (
              <button
                onClick={() => {
                  if (socketRef.current) {
                    socketRef.current?.emit(
                      "packet",
                      {
                        ip: packet?.ip,
                        udp: packet?.udp,
                        tcp: packet?.tcp,
                        hexcode: packet?.hexcode,
                        mac: playlistInfo?.mac,
                      },
                      () => toast.success("Packet sent.")
                    );
                  }
                }}
                style={{
                  height: "70px",
                  minWidth: "150px",
                  background: "var(--primary-color)",
                  color: "white",
                  cursor: "pointer",
                  borderRadius: "5px",
                  // margin:'0 10px'
                }}
              >
                {packet.name}
              </button>
            ))}
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default Setting;
