import { api } from "../../api/api";
import { GET_PARTNER_FAILED, GET_PARTNER_REQUEST, GET_PARTNER_SUCCESS, PARTNER_DELETE_SUCCESS, PARTNER_SAVE_SUCCESS, RESET_PARTNER, USER_SAVE_FAILED } from "../constant";


export const partnerRegister=(obj)=>{
  return async (dispatch)=>{
    const user = await api.post(`/user/register`, obj);
    if(user.status===201){
      dispatch({
        type:PARTNER_SAVE_SUCCESS,
        payload: user.data.user,
      });
      if(user.data.isRegistered){
      }
      else{
        dispatch({
          type: USER_SAVE_FAILED,
          payload: "",
        });
      }
    }
  }
}
export const getPartners = (id) => {
    return async (dispatch) => {
      dispatch({
        type: GET_PARTNER_REQUEST,
        payload: "",
      });
      const user = await api.get(`/user/getpartners/${id}`);
      if (user.status === 200) {
        if (user.data.users.length > -1) {
          dispatch({
            type: GET_PARTNER_SUCCESS,
            payload: user.data.users,
          });
        } else {
          dispatch({
            type: GET_PARTNER_FAILED,
            payload: "",
          });
        }
      }
    };
  };
  export const deletePartner = (id) => {
    return async (dispatch) => {
      const user = await api.get(`/user/deleteuser/${id}`);
      // console.log(user)
      if (user.status === 201) {
        dispatch({
          type: PARTNER_DELETE_SUCCESS,
          payload: id,
        });
      }
    };
  };
  export const resetPartner=()=>{
    return async (dispatch)=>{
      dispatch({
        type:RESET_PARTNER,
        payload:''
      })
    }
  }